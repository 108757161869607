<template>
  <main>
    <header-main />
    <home-content />
    <home-about-us />
    <home-contact-us />
    <home-collapse />
    <home-footer-contact />
  </main>
</template>

<script>
export default {
    name: 'HomePage',
    components: {
        HeaderMain: () => import('@/components/HeaderMain'),
        HomeContent: () => import('@/components/HomeContent'),
        HomeAboutUs: () => import('@/components/HomeAboutUs'),
        HomeContactUs: () => import('@/components/HomeContactUs'),
        HomeCollapse: () => import('@/components/HomeCollapse'),
        HomeFooterContact: () => import('@/components/HomeFooterContact')
    }
}
</script>
